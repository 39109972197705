import * as React from "react";
import './header.scss';
import Icon from "../../../assets/svg/marble.svg";
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Header = () => {
    return (
        <nav className="header">
            <div className="icon-container">
                <img src={Icon} alt="Marble logo" /> <h1><a href="/">marble</a></h1>
            </div>
            <div className="navigation">
                <AnchorLink className="features-link" href="#features" offset='200'>Features</AnchorLink>
                <AnchorLink className="pricing-link"  href="#pricing">Pricing</AnchorLink>
                <a className="get-started" href="/start">Get started</a>
            </div>
        </nav>
    )
}

export default Header;