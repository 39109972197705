import * as React from "react";
import "./footer.scss";
import Icon from "../../../assets/svg/marble.svg";
import { Link } from 'gatsby';

const Footer = () => {
    return (
        <footer>
            <div className="icon-container">
                <img src={Icon} alt="Marble logo" /> <h5><a href="/">marble</a></h5>
            </div>
            <div />
            <div className="linksContainer">
                <p>©{ new Date().getFullYear() } Marble, Ltd.</p>
                <div className="links">
                    <Link to="/contact">Contact Us</Link>
                    <Link to="/privacy">Terms and Privacy</Link>
                </div>

            </div>
        </footer>
    )
}

export default Footer;